const config = {
//   env: "prod",
  // env: 'testbed',
//   env: "uat",
  env: "sit",
  // other data here
  // env: "demo",
};

export default config;
